@tailwind base;
@layer base {
	img {
	  @apply inline-block;
	}
  }
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Bloomer;
    src: url("../public/fonts/Bloomer.ttf") format("opentype");
}

@font-face {
    font-family: TT;
    src: url("../public/fonts/TT.otf") format("opentype");
}

@font-face {
    font-family: TTBold;
    src: url("../public/fonts/TTBold.otf") format("opentype");
}

@font-face {
    font-family: Anton;
    src: url("../public/fonts/Anton-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Pacifico;
    src: url("../public/fonts/Pacifico-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Caveat;
    src: url("../public/fonts/Caveat-VariableFont_wght.ttf") format("opentype");
}

@font-face {
    font-family: Jersey;
    src: url("../public/fonts/Jersey20-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Varela;
    src: url("../public/fonts/VarelaRound-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Pop;
    src: url("../public/fonts/Pop.otf") format("opentype");
}

@font-face {
    font-family: Loverine;
    src: url("../public/fonts/Loverine.otf") format("opentype");
}

@font-face {
    font-family: AmsiPro;
    src: url("../public/fonts/AmsiPro.ttf") format("opentype");
}


@font-face {
    font-family: UnicornPop;
    src: url("../public/fonts/Unicorn Pop.ttf") format("opentype");
}

select { 
	appearance: none;
	
} 

.background-container {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden; /* Oculta el contenido que se desplaza fuera del contenedor */
  }
  
  .stars-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 200%;
	height: 100%; /* El contenedor debe ser el doble de alto para el loop infinito */
  }
  
  .stars-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	background-repeat: repeat-x;
	opacity: 0.3;
  }

@keyframes scalePlaceholder {
	0%   {scale: 1;}
	50%  {scale: 1.2;}
	100% {scale: 1;}
  }

.personalizaText::placeholder {
	animation-name: scalePlaceholder;
  	animation-duration: 4s;
	animation-iteration-count: infinite;
}

.myVideo {
	position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
	z-index: -1;
	overflow: hidden;
  }

  .myBG {
	position: absolute;
    width: 100%;
    object-fit: cover;
	z-index: -1;
	overflow: hidden;
  }

.parallax {
    overflow: hidden;
    line-height: 2;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  
  .parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    white-space: nowrap;
  }



.loader-container {
	width: 84px;
	height: 80px;
	position: absolute;
	-moz-animation: scaleUp 0.5s linear;
	-webkit-animation: scaleUp 0.5s linear;
	animation: scaleUp 0.5s linear;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0;
	pointer-events: none !important;
  }
  .loader-container .loader {
	background: url('../public/assets/pastelitoLOW.png');
	background-size: 100%;
	height: 100%;
	width: 100%;
	pointer-events: none !important;
  }
  .loader-container .loader::after {
	content: "";
	position: absolute;
	height: 80px;
	width: 80px;
	left: 10px;
	top: 10px;
	border-radius: 100%;
	pointer-events: none !important;
  }
  
  @-moz-keyframes filterHue2 {
	50% {
	  -webkit-filter: hue-rotate(1000deg);
	  filter: hue-rotate(1000deg);
	}
	100% {
	  -webkit-filter: hue-rotate(2000deg);
	  filter: hue-rotate(2000deg);
	}
  }
  @-ms-keyframes filterHue2 {
	50% {
	  -webkit-filter: hue-rotate(1000deg);
	  filter: hue-rotate(1000deg);
	}
	100% {
	  -webkit-filter: hue-rotate(2000deg);
	  filter: hue-rotate(2000deg);
	}
  }
  @keyframes filterHue2 {
	50% {
	  -webkit-filter: hue-rotate(1000deg);
	  filter: hue-rotate(1000deg);
	}
	100% {
	  -webkit-filter: hue-rotate(2000deg);
	  filter: hue-rotate(2000deg);
	}
  }
  @-moz-keyframes filterHue {
	50% {
	  -webkit-filter: hue-rotate(1000deg);
	  filter: hue-rotate(1000deg);
	}
	100% {
	  -webkit-filter: hue-rotate(2000deg);
	  filter: hue-rotate(2000deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-ms-keyframes filterHue {
	50% {
	  -webkit-filter: hue-rotate(1000deg);
	  filter: hue-rotate(1000deg);
	}
	100% {
	  -webkit-filter: hue-rotate(2000deg);
	  filter: hue-rotate(2000deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @keyframes filterHue {
	50% {
	  -webkit-filter: hue-rotate(1000deg);
	  filter: hue-rotate(1000deg);
	}
	100% {
	  -webkit-filter: hue-rotate(2000deg);
	  filter: hue-rotate(2000deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-moz-keyframes scaleUp {
	50% {
	  -moz-transform: scale(0.5);
	  -ms-transform: scale(0.5);
	  -webkit-transform: scale(0.5);
	  transform: scale(0.5);
	  opacity: 1;
	}
	100% {
	  -moz-transform: scale(0.25);
	  -ms-transform: scale(0.25);
	  -webkit-transform: scale(0.25);
	  transform: scale(0.25);
	}
  }
  @-ms-keyframes scaleUp {
	50% {
	  -moz-transform: scale(0.5);
	  -ms-transform: scale(0.5);
	  -webkit-transform: scale(0.5);
	  transform: scale(0.5);
	  opacity: 1;
	}
	100% {
	  -moz-transform: scale(0.25);
	  -ms-transform: scale(0.25);
	  -webkit-transform: scale(0.25);
	  transform: scale(0.25);
	}
  }
  @keyframes scaleUp {
	50% {
	  -moz-transform: scale(0.5);
	  -ms-transform: scale(0.5);
	  -webkit-transform: scale(0.5);
	  transform: scale(0.5);
	  opacity: 1;
	}
	100% {
	  -moz-transform: scale(0.25);
	  -ms-transform: scale(0.25);
	  -webkit-transform: scale(0.25);
	  transform: scale(0.25);
	}
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.loader {
	  background: url('../public/assets/firulito.png') !important;
	}
  }
  


  /* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #397524 #DFE9EB;
}*/

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
	height: 2px;
	width: 2px;
  }
  *::-webkit-scrollbar-track {
	border-radius:15px;
	background-color: #b8c0c200;
  }
  
  *::-webkit-scrollbar-track:active {
	background-color: #b8c0c200;
  }
  
  *::-webkit-scrollbar-thumb {
	border-radius: 15px;
	background-color: #5e7ce7;
	border: 3px solid #ffffff00;
  }
  
  *::-webkit-scrollbar-thumb:hover {
	background-color: #5e7ce7;
  }
  
  *::-webkit-scrollbar-thumb:active {
	background-color: #5e7ce7;
  }



  .normal{
	border-radius: 5px;
  }

  .tipo1{
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 5px;
	padding: 5px;
  }

  .tipo2{
	background-color: rgba(255, 255, 255, 0.5);;
	border-radius: 5px;
	padding: 5px;
  }

  .tipo3{
	text-decoration: underline;
	border-radius: 5px;
  }

